/* bootstrap */
@import "~bootstrap/scss/bootstrap";

/* settings */
@import 'settings/breakpoint';
@import 'settings/color';
@import 'settings/zindex';
@import 'settings/media';

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: greyscale;
}

#global-wrapper {
    background: linear-gradient(white, $light-blue);
    padding: 20px 10px 10px;
}

.title {
    padding-top: 30px;
    color: $blue;
    margin: 0;
}

.red {
    color: $red;
    font-weight: 600;
}

.loading {
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(../images/ajax-loader.gif);
    height: auto;
    min-height: 150px; 
}

// navbar
.navbar {
    padding: 0.5rem 0;
    .navbar-brand {
        img {
            height: 100px;
        }
    }
    .navbar-nav /*ul*/ {
        justify-content: space-around;
        align-items: center;
        width: 100%;
        .nav-item {
            font-weight: 600;
            .nav-link {
                color: $blue;
            }
        }
        .nav-item:last-child {
            font-weight: 400;
            .nav-link {
                color: $red;
            }
        }
        .active {
            .nav-link {
                text-decoration: underline;
            }
        }
        .separator {
            border: 5px solid transparent;
            border-bottom-color: $blue;
            height: 0;
            position: relative;
            top: -5px;
            width: 0;
        }
        .separator::after {
            border: 5px solid transparent;
            border-top-color: $blue;
            content: '';
            height: 0;
            left: -5px;
            position: absolute;
            top: 5px;
            width: 0;
        }
    }
}

footer {
    padding-top: 20px;
    font-size: 0.9rem;
    .footer-logo-wrapper {
        justify-content: center;
        display: flex;
        img {
            height: 70px;
        }
    }
    .navbar {
        justify-content: center;
        .navbar-nav /*ul*/ {
            justify-content: center;
            .nav-item {
                font-weight: 400;
            }
            .nav-item:last-child {
                .nav-link {
                    color: $blue;
                }
            }
            .separator {
                border: 2px solid transparent;
                border-bottom-color: $blue;
                top: -2px;
            }
            .separator::after {
                border: 2px solid transparent;
                border-top-color: $blue;
                left: -2px;
                top: 2px;
            }
        }
    }
}

.contests {
    .contests-wrapper {
        padding: 10px 0;
        margin: 0;
        .contest {
            padding: 5px 0;
            a {
                margin: 0;
                border: 1px solid $blue;
                border-radius: 25px;
                padding: 15px 5px;
                color: $blue;
                img {
                    border-radius: 25px;
                }
                .text-wrapper {
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                }
            }
            a:hover {
                background-color: $light-blue;
                text-decoration: none;
            }
        }
    }
}

// ARTICLES
.section-first {
    padding-top: 10px !important;
    @media screen and (max-width: 992px) {
        padding-top: 5px !important;
    }
}

.section-last {
    padding-bottom: 10px !important;
    @media screen and (max-width: 992px) {
        padding-bottom: 5px !important;
    }
}

.section-text-1col-wrapper, .section-text-2col-wrapper, .section-text-image-wrapper, .section-image-wrapper {
    padding-top: 20px;
    padding-bottom: 10px;
    @media screen and (max-width: 1200px) {
        padding-top: 10px;
        padding-bottom: 5px;
    }
    h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: unset;
        line-height: unset;
    }
    .text-content { 
        margin: 10px 0;
        & > ul {
            list-style-type: none;
            & > li::before {
                content: "•";
                margin-left: -14px;
                margin-right: 15px;
                font-size: 20px;
                color: $blue;
            }
        }
        a:not(.a-btn) {
            color: $blue;
            text-decoration: underline;
        }
    }
}
.section-text-image-wrapper {
    .container {
        position: relative;
        .img {
            flex-flow: column;
            img {
                margin: 10px 0;
            }
            img:first-child {
                margin-top: 0;
            }
            img:last-child {
                margin-bottom: 0;
            }
        }
        .text {
            display: flex;
            flex-flow: column;
            justify-content: center;
        }
        .right.img {
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 768px) {
                display: block;
            }
        }
        .left.img {
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 768px) {
                display: block;
            }
        }
        .left-text {
            flex-direction: row-reverse;
            .text {
                @media screen and (min-width: 992px) {
                    padding-right: 30px;
                }
                .link-wrapper {
                    justify-content: flex-start;
                }
            }
        }
    }
}
.section-image-wrapper {
    .container {
        picture {
            img {
                max-width: 100%;
                height: auto;
                width: 100%; 
                margin-bottom: 20px;
            }
        }
    }
}

.contest, .page, .contests {
    color: $blue;
    #articles {
        padding: 10px 0 20px;
        .separator {
            border-top: 1px solid $blue;
            border-bottom: 1px solid $blue;
            padding: 10px 0 !important;
            font-weight: 600;
            font-size: 24px;
        }
    }
    .content-wrapper {
        background-color: $white;
        padding: 20px 50px;
        border-radius: 10px;
    }
}
.contest {
    form#participation {
        label {
            font-weight: 600;
        }
        .checkbox-wrapper {
            margin: 10px 0 5px;
            .form-group {
                margin: 5px;
            }
            label {
                font-size: 14px;
                font-weight: 400;
                color: $black;
            }
        }
        input:not([type='checkbox']), select {
            height: 50px;
            background: linear-gradient($grey, $white);
            box-shadow: 1px 2px 2px $grey;
        }
        input::placeholder, select {
            font-style: italic;
            font-size: 14px;
        }
        .form-controls {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 10px 0 20px;
            .btn {
                font-size: 600;
                background-color: $red;
                border-color: $red;
                text-transform: uppercase;
                padding: 10px 40px;
            }
        }
        .required-fields {
            padding: 10px;
            border-top: 1px solid $light-blue;
            font-size: 14px;
        }
    }
}